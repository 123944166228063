.dhx_month_head.dhx_year_event {
	background-color: #fff !important;
	color: rgba(0, 0, 0, .54) !important;
}

.dhx_month_head.dhx_year_event {
	background-color: #fff !important;
	color: rgba(0, 0, 0, .54) !important;
}

.dhx_now>.dhx_month_head {
	background-color: #e7e7e7 !important;
	color: #0288d1 !important;
}

.dhx_calendar_click {
	background-color: #fff !important;
	color: rgba(0, 0, 0, .54) !important;
}

.delete-button_set {
	background-color: #c45844;
	color: #fff;
}

@media (max-width: 900px) {
	.dhx_cal_light {
		width: 95%;
		left: 8px
	}
}

@media (min-width: 901px) {
	.dhx_cal_light {
		width: 700px;
	}
}

.dhtmlx-custom-input-label {
	height: 26px;
	float: left;
	width: 77px;
	margin: 0 4px 16px 4px;
	text-align: center;
	border: 0px;
	font-weight: 500;
	color: rgba(0, 0, 0, .75);
	font-family: Roboto, Arial;
}

.dhx_section_time.dhx_lightbox_minical input.dhx_readonly:nth-child(5) {
	display: none;
}

.dhx_cal_date {
	width: 60px !important;
	overflow: hidden;
}

.my_event {
	background-color: rgba(217, 234, 251, 0.8) !important;
	overflow: hidden;
	color: black !important;
	border: none !important;
}

.event_status {
	width: 8px;
	height: 8px;
	border-radius: 8px;
	display: inline-block;
}

.event_status_month::before {
	content: ' ';
	width: 8px;
	height: 8px;
	border-radius: 8px;
	display: inline-block;
	position: absolute;
	top: 6px;
	left: 9px;
}

.event_status_month {
	background-color: rgba(217, 234, 251, 0.8) !important;
	overflow: hidden;
	color: black !important;
	border: none !important;
}

.event_status_agenda::before {
	content: ' ';
	width: 8px;
	height: 8px;
	border-radius: 8px;
	display: inline-block;
	z-index: 9999 !important;
	margin-bottom: 1px;
}

.event_status_color_yellow::before {
	background-color: yellow;
}

.event_status_color_brown::before {
	background-color: brown;
}

.event_status_color_orange::before {
	background-color: orange;
}

.event_status_color_purple::before {
	background-color: purple;
}

.event_status_color_pink::before {
	background-color: pink;
}

.event_status_color_blue::before {
	background-color: blue;
}

.event_status_color_green::before {
	background-color: green;
}

.swal2-container {
	z-index: 9999 !important;
}

/* fieldset:not(:hover):not(:disabled) {
	border-color: #a0d8ffcc!important;
}

.css-yk16xz-control {
	border-color: #a0d8ffcc!important;
} */

.MuiFormLabel-root {
	color: #2196f3b5!important;
	font-size: 1rem!important;
	padding: 0;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1;
}

.app {
	/* mix-blend-mode: overlay; */
}

.hide {
	display: none!important;
}

@media (display-mode: standalone) {
	.install-app-btn-container {
	  display: none!important;
	}
 }

 @media (display-mode: minimal-ui) {
	.install-app-btn-container {
	  display: none!important;
	}
 }

 @supports (-webkit-touch-callout: none) {
	.install-app-btn-container {
		display: none!important;
	}
 }

 button.MuiButtonBase-root:has(span.material-icons.primary) {
	border: 1px solid;
    border-radius: 10px;
    border-color: gray;
	margin-left: 10px;
 }

 span.material-icons.primary {
    color: #006cbb;
    font-size: 23px;
}